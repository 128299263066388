<template>
  <!-- 段落标题 主要设置 标题下的下划线 -->
  <div class="paragraph-title" :class="textDirection">
    <h2 class="title-name" :class="linePosition">{{ title }}</h2>
  </div>
</template>

<script>
export default {
  name: 'ParagraphTitle',
  props: {
    title: String,
    linePosition: {
      type: String,
      default: 'line-center'
    },
    textDirection: String
  }
}
</script>

<style lang="scss" scoped>
.paragraph-title {
  display: flex;
  justify-content: center;

  .title-name {
    position: relative;
    margin: 0;
    display: inline-block;
    padding: 15px 0;
    font-size: 40px;
    color: #333;
  }
}

// 横线 位置
.line-center::after {
  position: absolute;
  text-align: center;
  bottom: -6px;
  content: '';
  width: 67px;
  display: block;
  border-bottom: 6px solid #3979ff;
  box-shadow: 3px 3px 6px rgba(1, 67, 204, 0.35);
  left: 50%;
  transform: translateX(-50%);
}

.line-left::after {
  position: absolute;
  text-align: center;
  bottom: -6px;
  content: '';
  width: 67px;
  display: block;
  border-bottom: 6px solid #3979ff;
  box-shadow: 3px 3px 6px rgba(1, 67, 204, 0.35);
  left: 4px;
}

// 文字 位置
.text-left {
  justify-content: flex-start;
}

.text-right {
  justify-content: flex-end;
}
</style>
