<template>
  <!-- 新闻资讯 -->
  <div>
    <div class="head-img" ref="newsHeadImg">
      <img
        style="width: 100%; height: auto;"
        src="@/assets/images/news-bg.png"
        alt="新闻资讯"
      />
    </div>

    <ParagraphTitle title="新闻资讯" />

    <div class="news-box container-w1200">
      <div
        class="news-item"
        v-for="(item, index) in infoList"
        :key="item.title"
        @click="seeNews(item, index)"
      >
        <div class="news-img-box">
          <img class="news-img" :src="item.img" alt="" />
        </div>
        <div class="news-info">
          <h4 class="news-title">{{ item.title }}</h4>
          <p class="news-text">{{ item.tips }}</p>
          <span class="news-date">{{ item.date }}</span>
        </div>
      </div>
    </div>

    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      :current-page.sync="currentPage"
      @current-change="handleCurrentChange"
    >
    </el-pagination>

    <!-- 版权 -->
    <Copyright />
  </div>
</template>

<script>
import dataInfo from '@/utils/newsInfo'
import ParagraphTitle from '@/components/ParagraphTitle'
import Copyright from '@/components/Copyright'

export default {
  name: 'News',
  components: {
    ParagraphTitle,
    Copyright
  },

  data() {
    return {
      pageSize: 10,
      currentPage: 1,
      total: dataInfo.length,
      // 新闻消息
      infoList: []
    }
  },

  mounted() {
    this.getNewsInfo()
  },

  beforeRouteEnter(to, from, next) {
    if (from.name !== 'Newsdetail') {
      next()
      return
    }
    next((vm) => {
      vm.$nextTick(() => {
        const top = sessionStorage.getItem('top')
        if (top) {
          vm.scrollTop(Number(top))
        }
      })
    })
  },

  methods: {
    // 获取 新闻列表
    getNewsInfo() {
      this.infoList = dataInfo.slice(
        this.pageSize * (this.currentPage - 1),
        this.pageSize * this.currentPage
      )
    },

    // 点击查看新闻
    seeNews(item, index) {
      console.log(item)
      // 保存 新闻的索引
      sessionStorage.setItem(
        'newsIndex',
        this.pageSize * (this.currentPage - 1) + Number(index)
      )

      // 保存 滚动的高度
      let top = document.body.scrollTop || document.documentElement.scrollTop
      sessionStorage.setItem('top', top)

      // 保存 新闻详情
      this.$router.push({ name: 'Newsdetail' })
    },

    handleCurrentChange(val) {
      this.currentPage = val
      this.getNewsInfo()

      // 跳转页面后 页面滚动到顶
      const newsHeadImg = this.$refs.newsHeadImg
      this.$nextTick(() => {
        this.scrollTop(newsHeadImg.offsetHeight)
      })
    },

    // 滚动到顶部
    scrollTop(height) {
      let top = document.body.scrollTop || document.documentElement.scrollTop
      if (top > height) {
        let scrollToptimer = setInterval(function() {
          top = top - 40
          if (top > height) {
            window.scrollTo(0, top)
          } else {
            clearInterval(scrollToptimer)
          }
        }, 20)
      } else {
        let scrollToptimer = setInterval(function() {
          top = top + 40
          if (top < height) {
            window.scrollTo(0, top)
          } else {
            clearInterval(scrollToptimer)
          }
        }, 20)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.head-img {
  max-height: 250px;
  overflow: hidden;
  margin-bottom: 45px;
}
.news-box {
  margin: 48px auto;

  .news-item {
    display: flex;

    &:hover .news-info {
      background-color: rgb(245, 245, 245);
    }

    padding: 5px 0;
    border-bottom: 1px solid #e5e5e5;

    .news-img-box {
      width: 320px;
      height: 180px;
      overflow: hidden;

      .news-img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }

    .news-info {
      // margin-left: 20px;
      padding: 38px 0;
      padding-left: 20px;
      box-sizing: border-box;
      color: #999;
      font-size: 14px;
      overflow: hidden;
      flex: 1;
      cursor: pointer;
      transition: all 0.3s;

      .news-title {
        color: #333;
        font-size: 24px;
      }

      .news-text {
        width: 100%;
        margin: 18px 0 27px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

.el-pagination {
  text-align: center;
  margin-bottom: 46px;
}

::v-deep .el-pagination .number {
  margin: 0 0 0 -1px !important;
  background-color: #fff !important;
  border: 1px solid #e5e5e5;
}

::v-deep .el-pagination .active {
  margin: 0 0 0 -1px !important;
  color: #fff !important;
  background-color: #2c6eec !important;
  border-color: #2c6eec !important;
}

.el-pagination ::v-deep .btn-prev {
  margin: 0 0 0 -1px !important;
  background-color: #fff !important;
  border: 1px solid #e5e5e5;
}

.el-pagination ::v-deep .btn-next {
  margin: 0 0 0 -1px !important;
  background-color: #fff !important;
  border: 1px solid #e5e5e5;
}

.el-pagination ::v-deep .more {
  margin: 0 0 0 -1px !important;
  background-color: #fff !important;
  border: 1px solid #e5e5e5;
}
</style>
